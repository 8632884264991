import React from 'react';
import LogoIReferIcon from '../../assets/images/icon-logo-irefer-trademark.png';
import { Helmet } from 'react-helmet-async';
import { Wrapper } from 'components/Common/Styled/CommonStyled';
import {
  CustomBox,
  Logo,
  MessageParagraph,
} from 'pages/PageNotFound/PageNotFoundStyled';
import { Link } from 'react-router-dom';

function RedirectPageAfterResetPassword() {
  const navigateTo_iRefer = () => {
    window.location.href = 'https://www.i-refer.app/';
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Successfully Updated Password</title>
        <meta
          name="description"
          content="IRefer: You have successfully updated your password"
        />
      </Helmet>
      <CustomBox>
        <Logo src={LogoIReferIcon} onClick={navigateTo_iRefer} />
        {/* <CodeText>UNDER MAINTENANCE</CodeText> */}
        <MessageParagraph>
          "You have successfully updated your password".
        </MessageParagraph>
        <MessageParagraph>
          To log in as a Referrer, please open the iRefer App, or to log in as a
          Merchant please <Link to="/auth/login">click here</Link>.
        </MessageParagraph>

        {/* <RoundedButton variant="contained" onClick={navigateTo_iRefer}>
          Go to iRefer website
        </RoundedButton> */}
      </CustomBox>
    </Wrapper>
  );
}

export default RedirectPageAfterResetPassword;
