import React, { useEffect } from 'react';
import { Logo, StyledLoadingButton, StyledStack } from '../AuthStyled';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Stack, IconButton, InputAdornment } from '@mui/material';
import FormikControl, {
  CONTROL_TYPE,
} from '../../../components/Formik/FormikControl';
import { useImmer } from 'use-immer';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useResetPassword } from '../../../hooks/Auth/useAuth';
import { StyledBox } from '../../../components/Common/Styled/CommonStyled';
import {
  CommonSubtext,
  CommonTitleText,
} from '../../../components/Common/Styled/TypographyStyed';
import LogoIReferIcon from '../../../assets/images/icon-logo-irefer-trademark.png';

const validationSchema = Yup.object({
  password: Yup.string()
    .min(8, 'Check the password requirements below')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Check the password requirements below',
    )
    .required('Check the password requirements below'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
});

type ResetPasswordValues = {
  password: string;
  confirmPassword: string;
};

const initialValues: ResetPasswordValues = {
  password: '',
  confirmPassword: '',
};

const ResetPassword = () => {
  const { code, email } = useParams();
  const [searchParams] = useSearchParams();

  const isCameFromReferrer = searchParams.get('type') === 'REFERRER';

  const navigate = useNavigate();
  useEffect(() => {
    if (!code || !email) {
      navigate('/invalid-link');
    }
  }, [code, email, navigate]);
  const { mutate: mutateForgotPassword, isLoading } = useResetPassword();
  const onSubmit = (values: ResetPasswordValues) => {
    const payload = {
      email: email ?? '',
      code: code ?? '',
      newPassword: values.password,
    };

    mutateForgotPassword(payload, {
      onSuccess: () =>
        isCameFromReferrer
          ? navigate('/redirect-page-after-reset-password')
          : navigate('/auth/login'),
      onError: () => navigate('/invalid-link'),
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };
  const [showPassword, setShowPassword] = useImmer({
    password: false,
    confirmPassword: false,
  });

  const handleClickShowPassword = (name: string) => {
    setShowPassword((draft: any) => {
      draft[name] = !draft[name];
    });
  };

  const formikProps = { initialValues, validationSchema, onSubmit };

  return (
    <Formik {...formikProps}>
      {({ isValid, handleSubmit }) => (
        <Form>
          <Helmet>
            <title>Reset Password</title>
            <meta name="description" content="iRefer Reset Password" />
          </Helmet>
          <StyledBox>
            <StyledStack>
              <Stack alignItems="center" mb={2}>
                <Logo
                  alt="iReferLogo"
                  src={LogoIReferIcon}
                  style={{ width: '104px' }}
                />
              </Stack>
              <CommonTitleText>RESET YOUR PASSWORD</CommonTitleText>
              <CommonSubtext mb="1rem">
                Create a new secure password for your iRefer account.
              </CommonSubtext>
              <Stack mb="2rem" gap={3}>
                <FormikControl
                  autoCapitalize="none"
                  control={CONTROL_TYPE.PASSWORD}
                  name="password"
                  label="New Password"
                  type={showPassword.password ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword('password')}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword.password ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormikControl
                  autoCapitalize="none"
                  control={CONTROL_TYPE.INPUT}
                  name="confirmPassword"
                  label="Confirm Password"
                  type={showPassword.confirmPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            handleClickShowPassword('confirmPassword')
                          }
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword.confirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <StyledLoadingButton
                loading={isLoading}
                variant="contained"
                disabled={!isValid}
                onClick={() => {
                  handleSubmit();
                }}
                sx={{ marginBottom: '1.2rem' }}
              >
                Done
              </StyledLoadingButton>
            </StyledStack>
          </StyledBox>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPassword;
