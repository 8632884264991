import React, { Suspense, useEffect } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { ThemeProvider, CssBaseline } from '@mui/material';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './redux/store';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { themeMaterial } from './theme';
import { Amplify, Auth } from 'aws-amplify';
import amplifyConfig from './aws-exports';
import ToastMessage, {
  ToastType,
} from './components/Common/ToastMessage/ToastMessage';
import commonConstants from './constants/common.constant';
import { logout } from './redux/slices/authSlice';
// import loadable from '@loadable/component';
import AuthRoutes from './pages/Auth/AuthRoutes';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import LayoutWithNav from './components/Layout/LayoutWithNav';
import Home from './pages/Home';
import Account, {
  LinkBankAccount,
  Notifications,
  Payments,
  Settings,
} from './pages/Account';
import Referrers from './pages/Referrers';
import Referrals from './pages/Referrals';
import ReferrerProfile from './pages/ReferrerProfile';
import ReferralDetail from './pages/ReferralDetail';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import Transactions from 'pages/Transactions';
import Transaction from 'pages/Transaction';
import StripeConnectSuccess from 'pages/StripeConnectSuccess';
import UnderMaintenance from 'pages/UnderMaintenance/UnderMaintenance';
import APIPlugins from 'pages/APIPlugins/APIPlugins';
import { useOnlyAllowedIfVendorIsApproved } from 'hooks/Auth/useAuth';
import TransitionStripe from 'pages/TransitionStripe';
import RedirectPageAfterResetPassword from 'pages/RedirectPageAfterResetPassword/RedirectPageAfterResetPassword';

// const AuthRoutes = loadable(() => import('./pages/Auth/AuthRoutes'));
// const LayoutWithNav = loadable(
//   () => import('./components/Layout/LayoutWithNav'),
// );
// const ProtectedRoute = loadable(
//   () => import('./components/Auth/ProtectedRoute'),
// );
// const PageNotFound = loadable(
//   () => import('./pages/PageNotFound/PageNotFound'),
// );
// const Home = loadable(() => import('./pages/Home'));
// const Referees = loadable(() => import('./pages/Referees'));
// const Referrals = loadable(() => import('./pages/Referrals'));
// const ReferrerProfile = loadable(() => import('./pages/ReferrerProfile'));
// const ReferralDetail = loadable(() => import('./pages/ReferralDetail'));
// const Account = loadable(() => import('./pages/Account'));
// const Payments = loadable(() => import('./pages/Account'), {
//   resolveComponent: (components) => components.Payments,
// });
// const Settings = loadable(() => import('./pages/Account'), {
//   resolveComponent: (components) => components.Settings,
// });
// const Notifications = loadable(() => import('./pages/Account'), {
//   resolveComponent: (components) => components.Notifications,
// });
// const LinkBankAccount = loadable(() => import('./pages/Account'), {
//   resolveComponent: (components) => components.LinkBankAccount,
// });

Amplify.configure(amplifyConfig);

const checkExpiredAndLogout = async (error: any) => {
  // Check Expired Time here to logout;
  try {
    await Auth.currentSession();
  } catch (err: any) {
    if (!!store.getState()?.auth?.user) {
      queryClient.invalidateQueries();
      persistor.purge();
      store.dispatch(logout());
      toast(
        <ToastMessage
          text={commonConstants.EXPIRED_SESSION_MESSAGE}
          type={ToastType.ERROR.type}
        />,
      );
    }
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        // Fallback Error Catch If we don't define onError when using useQuery
        toast(
          <ToastMessage
            text={
              error?.response?.data?.message ??
              error?.message ??
              commonConstants.SOMETHING_WENT_WRONG
            }
            type={ToastType.ERROR.type}
          />,
        );
      },
    },
    mutations: {
      retry: false,
      onError: (error: any) => {
        // Fallback Error Catch If we don't define onError when using useMutate
        toast(
          <ToastMessage
            text={
              error?.response?.data?.message ??
              error?.message ??
              commonConstants.SOMETHING_WENT_WRONG
            }
            type={ToastType.ERROR.type}
          />,
        );
      },
    },
  },
  queryCache: new QueryCache({
    onError: checkExpiredAndLogout,
  }),
  mutationCache: new MutationCache({
    onError: checkExpiredAndLogout,
  }),
});

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

type ApprovedVendorRoutesProps = {
  children: JSX.Element;
};

const ApprovedVendorRoutes = ({ children }: ApprovedVendorRoutesProps) => {
  useOnlyAllowedIfVendorIsApproved();
  return children;
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <HelmetProvider>
        <Helmet>
          <title>iRefer Vendor App</title>
        </Helmet>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={themeMaterial}>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <BrowserRouter>
                <ScrollToTop />
                <Suspense fallback={<></>}>
                  <Routes>
                    <Route path="/auth/*" element={<AuthRoutes />} />
                    <Route
                      path="/"
                      element={
                        <ProtectedRoute>
                          <LayoutWithNav />
                        </ProtectedRoute>
                      }
                    >
                      {/* Routes that require Vendor being approved */}
                      <Route
                        index
                        element={
                          <ApprovedVendorRoutes>
                            <Home />
                          </ApprovedVendorRoutes>
                        }
                      />
                      <Route
                        path="home"
                        element={
                          <ApprovedVendorRoutes>
                            <Home />
                          </ApprovedVendorRoutes>
                        }
                      />
                      <Route
                        path="transactions"
                        element={
                          <ApprovedVendorRoutes>
                            <Transactions />
                          </ApprovedVendorRoutes>
                        }
                      />
                      <Route
                        path="transactions/:transactionId"
                        element={
                          <ApprovedVendorRoutes>
                            <Transaction />
                          </ApprovedVendorRoutes>
                        }
                      />
                      <Route
                        path="referrers"
                        element={
                          <ApprovedVendorRoutes>
                            <Referrers />
                          </ApprovedVendorRoutes>
                        }
                      />
                      <Route
                        path="referrals"
                        element={
                          <ApprovedVendorRoutes>
                            <Referrals />
                          </ApprovedVendorRoutes>
                        }
                      />
                      <Route
                        path="referrer-profile/:referrerId"
                        element={
                          <ApprovedVendorRoutes>
                            <ReferrerProfile />
                          </ApprovedVendorRoutes>
                        }
                      />
                      <Route
                        path="referral/:referralId"
                        element={
                          <ApprovedVendorRoutes>
                            <ReferralDetail />
                          </ApprovedVendorRoutes>
                        }
                      />
                      {/* Routes that not require Vendor being approved */}
                      <Route path="api-plugins" element={<APIPlugins />} />
                      <Route path="account">
                        <Route index element={<Account />} />
                        <Route path="payments">
                          <Route index element={<Payments />} />
                          <Route
                            path="link-bank-account"
                            element={<LinkBankAccount />}
                          />
                        </Route>
                        <Route path="settings" element={<Settings />} />
                        <Route
                          path="notifications"
                          element={<Notifications />}
                        />
                      </Route>
                    </Route>
                    <Route
                      path="stripe-connect-success"
                      element={<StripeConnectSuccess />}
                    />
                    <Route
                      path="/under-maintenance"
                      element={<UnderMaintenance />}
                    />
                    <Route
                      path="/redirect-page-after-reset-password"
                      element={<RedirectPageAfterResetPassword />}
                    />
                    <Route
                      path="/transition-stripe-connect"
                      element={<TransitionStripe />}
                    />
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </Suspense>
              </BrowserRouter>
              <ToastContainer
                className="toaster-container"
                position="top-center"
                autoClose={6000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                closeButton={false}
              />
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </HelmetProvider>
    </QueryClientProvider>
  );
}

export default App;
